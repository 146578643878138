form {
  position: relative;
}

.form-group {
  margin-bottom: 1.5rem;
}
.form-check {
  margin-bottom: 0.5rem;
}
.form-group label {
  font-family: 'Manrope', sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0;
  padding: 5px 0;
  letter-spacing: 0.2px;
}
.form-check label {
  font-family: 'Manrope', sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.2px;
}

.form-group .form-control {
  border: 2px solid #eee;
  border-radius: 8px;
  font-family: 'Manrope', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: #111;
  display: inline-block;
  height: auto;
  outline: 0;
  padding: 0.5rem 1.25rem;
}
.form-group .form-control:focus {
  border-color: #999;
  box-shadow: none;
}
.form-group .form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #520000;
  background-image: none;
}
.form-group .invalid-feedback {
  color: #520000;
}
.form-group .form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #520000;
  box-shadow: none;
}

.form-check .form-check-input {
  border: 2px solid #eee;
  border-radius: 8px;
  box-shadow: none !important;
  outline: 0;
}
.form-check .form-check-input:focus {
  border-color: #999;
}
.form-check .form-check-input:checked {
  background-color: #999;
  border-color: #999;
}

.modal .modal-title {
  font-family: 'Manrope', sans-serif;
  font-weight: 700;
}
.modal .modal-content {
  border: 0;
}
.modal .modal-body {
  padding-bottom: 0;
}
.modal-body p {
  margin: 0;
}
.modal .modal-footer {
  border: 0;
  justify-content: center;
}
.modal-footer .btn {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

button.close {
  background-color: transparent;
}
button.btn {
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%);
  border: 0;
  border-radius: 1.5rem;
  color: #fff;
  font-family: 'Manrope', sans-serif;
  font-weight: 700;
  font-size: 1rem;
  display: block;
  padding: 0.75rem 2rem;
  margin-top: 1rem;
}
.btn.btn-primary {
  background-color: #672d2d;
  box-shadow: none;
}
.btn.btn-primary:focus,
.btn.btn-primary:hover,
.btn.btn-primary:active {
  background-color: #672d2d;
  box-shadow: 0 3px 15px 0 rgb(0 0 0 / 9%) !important;
}
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #672d2d !important;
}

.btn.btn-secondary {
  box-shadow: none;
}
.btn.btn-secondary:focus,
.btn.btn-secondary:hover,
.btn.btn-secondary:active {
  box-shadow: 0 3px 15px 0 rgb(0 0 0 / 9%) !important;
}

.btn-danger {
  background-color: #eb3e44;
  border-color: #eb3e44;
}

.full-mobile {
  width: 100%;
}
@media screen and (min-width: 992px) {
  .full-mobile {
    width: auto;
  }
}
