.winner {
    background: #fff;
    height: calc(100% - 2rem);
    margin-bottom: 2rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
    box-shadow: 0 5px 7px rgb(0 0 0 / 5%);
}

.winner-image {
    background: #eee;
    position: relative;
    height: 200px;
    overflow: hidden;
}

.winner-content {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    color: #333;
}

.winner-title {
    font-size: 1.2rem;
    line-height: 1.3;
    color: #533936;
    margin-bottom: 0.5rem;
    padding-top: 5px;
    font-family: 'Manrope';
    font-weight: 700;
    letter-spacing: -.5px;
    text-align: center;
}

.winner-name {
    font-size: 1.5rem;
    text-align: center;
}
