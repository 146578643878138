html {
  font-size: 80%;
}
body {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

div {
  position: relative;
}

button {
  border: 0;
}

a {
  color: #672d2d;
  text-decoration: none !important;
  transition: 0.25s;
}
a:hover {
  color: #65000a;
}

ul,
li {
  list-style: none;
}

img {
  height: auto;
  width: 100%;
}

.container {
  padding-right: 2rem;
  padding-left: 2rem;
}

section .box .title {
  text-align: center;
}

section.navigation {
  border-bottom: 1px #eee solid;
  padding: 2rem 0 2rem 0;
}
.menus {
  font-family: 'Manrope', sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
}
.menus a {
  color: #65000a;
}
.menus a:hover,
.menus a:focus {
  color: #533936;
}
.menus li {
  display: inline-block;
  border: 1px solid #65000a;
  border-radius: 8px;
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
}
.menus li + li {
  margin-left: 0;
}

section.banner {
  background: #65000a;
  color: #fff;
  font-family: 'Manrope', sans-serif;
  position: relative;
  padding: 6rem 0;
  overflow: hidden;
  text-align: center;
}
section.banner .background {
  background-size: cover;
  background-position: 50%;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}
section.banner .logo {
  margin-bottom: 3rem;
}
section.banner .logo img {
  height: 2.2rem;
  width: auto;
}
section.banner .title {
  margin-bottom: 1rem;
  text-transform: uppercase;
}
section.banner .title h1 {
  font-weight: 700;
}
section.banner .subtitle {
  margin-top: 3rem;
}
section.banner .subtitle h2 {
  font-size: 1.75rem;
  font-weight: 400;
}

section.light {
  background: #fff;
  position: relative;
  padding: 3rem 0;
}
section.light .title {
  font-family: 'Manrope', sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  color: #533936;
  line-height: 1.2;
  margin-bottom: 2rem;
}
section.light .title h3 {
  font-size: 2rem;
  font-weight: 700;
}
section.light .description {
  text-align: justify;
}
section.light .description p {
  font-weight: 400;
}

section.awards {
  background: #65000a;
  color: #fff;
  position: relative;
  padding: 3rem 0;
  text-align: center;
}
section.awards h3 {
  font-family: 'Manrope', sans-serif;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 2rem;
}
section.awards .box {
  border: 1px solid #fff;
  border-radius: 8px;
  margin-bottom: 30px;
  padding: 0.75rem;
}
section.awards .award h5 {
  font-size: 2rem;
  font-weight: 700;
}
section.awards .description p {
  margin: 0;
}

section.dark {
  background: #65000a;
  color: #fff;
  position: relative;
  padding: 3rem 0;
  text-align: center;
}
section.dark h3 {
  font-family: 'Manrope', sans-serif;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 2rem;
}

.dates {
  justify-content: center;
}
.calendar {
  font-family: 'Roboto', sans-serif;
  text-align: left;
  padding: 0;
}
.calendar .event {
  font-weight: 400;
}
.calendar .event + .event {
  margin-top: 1rem;
}
.calendar .time {
  margin-bottom: 0.5rem;
}
.calendar .time span {
  border: 1px solid #fff;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-weight: 700;
  text-align: center;
  padding: 0.5rem;
}
.calendar .thing span {
  border: 1px solid #fff;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}

.questions {
  padding: 0;
}
.questions .question {
  background-color: #fff;
}
.questions .text {
  border-bottom: 1px solid #ccc;
}
.questions .text button {
  width: 100%;
  background: none;
  border: 0;
  font-family: 'Manrope', sans-serif;
  font-weight: 700;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  text-align: left;
}
.questions .text span {
  margin-right: 2rem;
}
.questions .text i {
  color: #65000a;
}
.questions .collapsed .fa-minus {
  display: none;
}
.questions .fa-plus {
  display: none;
}
.questions .collapsed .fa-plus {
  display: block;
}
.questions .answer span {
  font-family: 'Manrope', sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: #444;
  display: block;
  padding: 1.5rem 2rem;
}

section.footer {
  background: #fafafa;
  border-top: 1px solid #eee;
}
.logos {
  text-align: center;
}
.logos img {
  height: 25px;
  width: auto;
}
.logos a + a {
  margin-left: 2rem;
}
.logos .projetou {
  height: 25px;
}
.logos .decroly {
  height: 15px;
}

@media screen and (min-width: 768px) {
  html {
    font-size: 90%;
  }
  .menus li {
    margin-bottom: 0;
    width: auto;
  }
  .menus li + li {
    margin-left: 1.25rem;
  }
  .calendar .time {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 992px) {
  html {
    font-size: 95%;
  }
}
@media screen and (min-width: 1200px) {
  html {
    font-size: 100%;
  }
}
